import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import SEO from "../components/seo"

const DataPolicy = () => {

    const i18n = useIntl().formatMessage

    return (
    <>
        <SEO title={ i18n({ id: "pages.data-policy.title" })} />
        <section className="section-padding">
            <div className="container">
                <div className="row center-xs">
                <div className="col col-xs-12 col-md-6">
                    <h1>{ i18n({ id: "pages.data-policy.title" })}</h1>
                    <p>{ i18n({ id: "pages.data-policy.text" })}</p>
                    <h2>{ i18n({ id: "pages.data-policy.subtitle_one" })}</h2>
                    <p>{ i18n({ id: "pages.data-policy.text_one" })}</p>
                    <h2>{ i18n({ id: "pages.data-policy.subtitle_two" })}</h2>
                    <p>{ i18n({ id: "pages.data-policy.text_two" })}</p>
                    <h2>{ i18n({ id: "pages.data-policy.subtitle_three" })}</h2>
                    <p>{ i18n({ id: "pages.data-policy.text_three" })}</p>
                    <h2>{ i18n({ id: "pages.data-policy.subtitle_four" })}</h2>
                    <p>{ i18n({ id: "pages.data-policy.text_four" })}</p>
                    <h2>{ i18n({ id: "pages.data-policy.subtitle_five" })}</h2>
                    <p>{ i18n({ id: "pages.data-policy.text_five" })}</p>
                </div>
                </div>
            </div>
        </section>
    </>
    )
    }

export default DataPolicy
